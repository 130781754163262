let SERVER_API_URL, REDIRECT_ANGULAR_URL

if(process.env.REACT_APP_ENV === 'dev'){
    SERVER_API_URL = 'https://dev3.walletly.ai/api/v3/'
    REDIRECT_ANGULAR_URL= 'https://dev4-app.walletly.ai/main/campaign-main/review-campaign/'
}

if(process.env.REACT_APP_ENV === 'live'){
    SERVER_API_URL = "https://app.walletly.ai/api/v3/";
    REDIRECT_ANGULAR_URL = 'https://webapp.walletly.ai/main/campaign-main/review-campaign/'
}

export {SERVER_API_URL, REDIRECT_ANGULAR_URL}
