

import React,{useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {FormService} from './services';

export default function Form() {


    let cardType;
    const [loader, setLoader] = useState(false);
    let passType;
    const search = useLocation().search;
    const accesstoken = new URLSearchParams(search).get('userToken');
    const campaign_code = new URLSearchParams(search).get('campaign_code');

    useEffect(() => {
        console.log(campaign_code);
        FormService.GetCampaignByCode(campaign_code).then((res)=>{
            console.log(res);
            document.getElementById('Form').innerHTML = res.data.data[0].formHtml;

            console.log('data', res.data.data[0].campaign_type);

            selectCardType(res.data.data[0].campaign_type);

        
            const brand_apiKey = res.data.data[0].brand_apiKey

            const data = {
                brand_id : res.data.data[0].brand_id,
                campaign_code : campaign_code,
            }
            document.getElementById('myform').addEventListener('submit',function(e){
                setLoader(true);
                e.preventDefault();
                console.log('e',e.target)
                for(let field of e.target.elements){
                    if(field.value !== "")
                    {
                        console.log(field.name)
                        console.log(field.value)
                        data[field.name]=  field.value;
                    }
                        
                }

                FormService.PostCardForm({brand_apiKey: brand_apiKey, card_type: cardType, data: data}).then((res)=>{
                    console.log(res);
                    if(res)
                    {
                        setLoader(false);
                       
                        window.location.href = res.data.data.coupon_url || res.data.data.stampCard_url || res.data.data.loyaltyCard_url || res.data.data.ticket_url || res.data.data.membershipCard_url;
                        
                        
                    }
                }).catch(err=>{
                    setLoader(false);
                    console.log(err);
                })
                console.log(data);
            })
        }).catch(err=>{
            setLoader(false);
            console.log(err);
        })
        
    }, [])



    const selectCardType = (type) => {
        if (type <= 4) {
           cardType = "coupon";
           passType = "coupon"
    
        } else if (type <= 5) {
           cardType = "loyaltyCard";
           passType = "loyalty card"
        } else if (type <= 6) {
           cardType = "stampCard";
           passType = "stamp card"
    
        } else if (type <= 7) {
           cardType = "membershipCard";
           passType = "membership card"
    
        } else if (type <= 9) {
           cardType = "ticket";
           passType = "ticket"
        }
      }
    

    return (
        <>
        {console.log('loader', loader)}
        {
            (loader === true) ? 
                <span style={{alignItems: 'center', display:'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center'}}>
                    <img src='https://cdn.walletly.ai/img/client-walletly-app/Loader2x.gif' width='120px' height='120px'/>
                </span>
            :
                <div id="Form">

                </div>
        }
        </>
    )
}