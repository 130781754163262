import React,{useEffect} from 'react'

export default function Preview(props) {

    useEffect(() => {
        console.log(props);

        const html = localStorage.getItem('html');
        document.getElementById('Form').innerHTML = html
     },[])

    return (
        <div id="Form">

        </div>
    )
}
