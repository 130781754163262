import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom'
import ReactDOM from 'react-dom';
import App from './App';
import Form from './FormExport';
import Preview from './Preview';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Route exact path="/" component={App}/>
      <Route exact path="/form" component={Form}/>
      <Route exact path="/preview" component={Preview}/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
