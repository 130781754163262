import axios from 'axios';
import { SERVER_API_URL } from '../enviornment/enviornment';


const AddFormtoCampaign = async (payload) => {
    console.log('add', payload.token)
    
    const data = {
        campaign_id: payload.campaign_id,
        formHtml: payload.formHtml,
        formJson: payload.formJson
    }

    const headers = {
        'content-type': 'application/json',
        'x-auth-token' : 'Bearer ' + payload.token
    }

    return axios.post(SERVER_API_URL + 'campaign/campaignform', data, {headers: headers})
}



const GetCampaignByCode = async (payload) => {
    
    return axios.get(SERVER_API_URL + "campaign/code/" + payload)
}



const PostCardForm = async (payload) => {
    console.log('add', payload)
    
    

    const headers = {
        'content-type': 'application/json',
        'x-api-key': payload.brand_apiKey
    }

    return axios.post(SERVER_API_URL + payload.card_type, payload.data, {headers: headers})
}






export {
    AddFormtoCampaign,
    GetCampaignByCode,
    PostCardForm
}