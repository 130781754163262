import React, {useEffect , useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import { REDIRECT_ANGULAR_URL } from './enviornment/enviornment';
import {FormService} from './services'

let unlayer;


const style = {
    backgroundColor: 'lightblue', 
    color: 'white',
    borderRadius: '5px',
    padding: 5,
    margin: 'auto',
}


const style2 = {
  backgroundColor: 'lightblue', 
  color: 'white',
  position: 'absolute',
  top:8,
  right: 10,
  borderRadius: '5px',
  padding: 5,
}







export default function App(props) {

  const [htmlExport, setHtmlExport] = useState();
  const history = useHistory();
  const search = useLocation().search;
  const accesstoken = new URLSearchParams(search).get('userToken');
  const campaign_id = new URLSearchParams(search).get('campaign_id');
  const campaign_code = new URLSearchParams(search).get('campaign_code');


  useEffect(() => {
    const unlayerURL = `https://editor.unlayer.com/embed.js`;
    if(campaign_code)
    {
      FormService.GetCampaignByCode(campaign_code).then((res)=>{
        console.log(res.data.data[0].formJson);

        unlayer.loadDesign(res.data.data[0].formJson);
      })
    }



    loadScript(unlayerURL, () => handleScriptLoaded());
  }, []);


  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

      if (script.readyState) {
          script.onreadystatechange = function () {
              if (script.readyState === "loaded" || script.readyState === "complete") {
                  script.onreadystatechange = null;
                  callback();
              }
          };
      } else {
          script.onload = () => callback();
      }

      script.src = url;
      document.getElementsByTagName("head")[0].appendChild(script);
  };



  const call = (e,preview) =>{
      e.preventDefault();   
      var check = true;
      handleScriptLoaded(check, preview);
  }


  const handleScriptLoaded = (check, preview) => {
    if(!check){
    unlayer = window.unlayer
  
    unlayer.init({
      id: 'editor-container',
      projectId: 1234,
      displayMode: 'web',
      tools: {
        form: {
          properties: {
            fields: {
              editor: {
                data: {
                  defaultFields: [
                    {name: "email", label: "Email", type: "email",show_label: "true"},
                    {name: "firstName", label: "First Name", type: "text", show_label: "true"},
                    {name: "lastName", label: "Last Name", type: "text", show_label: "true"},
                    {name: "phone", label: "Phone Number", type: "text", show_label: "true"},
                  ],
                  allowAddNewField: false
                }
              },
              value: [
                {
                  "name": "firstName",
                  "type": "text",
                  "label": "First Name",
                  "placeholder_text": "Enter First Name here",
                  "show_label": true,
                  "required": true
                },
                {
                "name": "email",
                "type": "email",
                "label": "Email",
                "placeholder_text": "Enter email here",
                "show_label": true,
                "required": true
              },
              {
                "name": "phone",
                "type": "text",
                "label": "Phone Number",
                "placeholder_text": "Enter Phone Number here",
                "show_label": true,
                "required": true
              }
            ],
            }
          }
        }
      }
    
    })
  
    unlayer.exportHtml(function(data) {console.log('Html is 1' + JSON.stringify(data)) })
  
    console.log(unlayer);
  }
    else{
  
      // setHtmlExport(unlayer.exportHtml(function(data) {console.log('Html is 2' + data.html.replace('<form action="" method="GET" style="display:inline-block;width:100%" target="_self">', '<form onSubmit={handleSubmit}>')) }))
      unlayer.exportHtml(function(data) {

        console.log('data', data);
          const ht1 = data.chunks.body.replace('<form action="" class="v-form-width-width" method="GET" style="display:inline-block;width:100%" target="_self">', '<form id="myform">')
          const ht2 = ht1.replace('<button type="submit" class="v-button-width-width v-button-font-size-font-size" style="border:none;display:inline-block;text-align:center;overflow:hidden;cursor:pointer;text-decoration:none;padding:10px;margin:5px 0px 0px;font-size:14px;width:100%;border-radius:4px;color:#FFF;background-color:#3AAEE0">', '<button type="submit" id="formbtn" class="v-button-width-width v-button-font-size-font-size" style="border:none;display:inline-block;text-align:center;overflow:hidden;cursor:pointer;text-decoration:none;padding:10px;margin:5px 0px 0px;font-size:14px;width:100%;border-radius:4px;color:#FFF;background-color:#3AAEE0">')
          
          if(preview == false)
          {
            FormService.AddFormtoCampaign({campaign_id:campaign_id, token:accesstoken, formHtml: ht2, formJson: data.design}).then((res)=>{
              console.log('res',res);
              window.location.href = REDIRECT_ANGULAR_URL + campaign_id;
            }).catch(err=>{
              console.log(err);
            })
            localStorage.setItem('html',ht2);
          }

          if(preview == true)
          { 
            localStorage.setItem('html',ht2);
            window.open(window.location.origin + '/preview');
          }

          // history.push({
          //   pathname: '/form',
          //   state: { detail: ht2,  accesstoken: accesstoken, campaign_id: campaign_id}
          // })
      })

  
    }
  }


  return (
      <>
      <button onClick={(e)=>{call(e,false)}} style={style}>Save & Export HTML</button>

      {console.log(typeof htmlExport)}
      <div id="editor-container" style={{height: '100vh', width: '100%'}}>

      </div>
      {
       <button style={style2} onClick={(e)=>{call(e,true)}}>Preview Form</button>
       
      }
      </>

  )
} 